import React from 'react';
import { useRouteMatch } from 'react-router';

import { EEventNames } from '../../../../../client/analytics';
import { usePageTracker } from '@common/hooks';
import UserProfile from '../../../core/containers/profile';
import { UserProfileTypes } from '../../../core/definitions';

const NetworkUserContainer = () => {
  usePageTracker(EEventNames.VISITED_NETWORK_USER_DETAIL_PAGE);
  const { params } = useRouteMatch<{ userId?: string }>();

  return (
    <UserProfile type={UserProfileTypes.FEED} userId={params.userId} />
  );
};

export default NetworkUserContainer;
