import * as React from 'react';
import { EEventNames } from '../../../../../client/analytics';
import { usePageTracker } from '@common/hooks';
import { useRouteMatch } from 'react-router';
import UserProfile from '../../../core/containers/profile';
import { UserProfileTypes } from '../../../core/definitions';

const OrganisationUserContainer = () => {
  usePageTracker(EEventNames.VISITED_ORGANISATION_USER_DETAIL_PAGE);
  const { params } = useRouteMatch<{ userId?: string }>();

  return (
    <UserProfile type={UserProfileTypes.ADMIN} userId={params.userId} />
  );
};

export default OrganisationUserContainer;
